/* @flow */

import React from 'react';
import { graphql } from 'relay-runtime';
import Layout from '../common/Layout';
import { isPartner } from '../utils';

export default [
  {
    path: '/about',
    query: graphql`
      query pagesAboutQuery {
        ...Layout
        me {
          id
        }
      }
    `,
    components: () => [import(/* webpackChunkName: 'about' */ './About')],
    render: ([About], data, ctx) => {
      if (!data.me && typeof window !== 'undefined' && !isPartner()) {
        return { redirect: `/early-access?return=${ctx.path}` };
      }
      return {
        title: 'About Us • Prepdup',
        component: (
          <Layout data={data} contentWidth="wide">
            <About data={data} />
          </Layout>
        ),
        chunks: ['about'],
      };
    },
  },
  {
    path: '/browser-not-supported',
    query: graphql`
      query pagesBrowserQuery {
        ...Layout
      }
    `,
    components: () => [
      import(/* webpackChunkName: 'browser-not-supported' */ './BrowserNotSupported'),
    ],
    render: ([BrowserNotSupported], data) => ({
      title: 'Browser Not Supported • Prepdup',
      component: (
        <Layout data={data} hideLinks={true}>
          <BrowserNotSupported />
        </Layout>
      ),
      chunks: ['browser-not-supported'],
    }),
  },
];
