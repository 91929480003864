/**
 * @flow
 * @relayHash 5015cd8356cc7cbe1f3c6fb7bc8bcd8b
 */

/* eslint-disable */

'use strict';

/*::
import type { ConcreteRequest } from 'relay-runtime';
type Layout$ref = any;
export type userSignUpQueryVariables = {||};
export type userSignUpQueryResponse = {|
  +$fragmentRefs: Layout$ref
|};
export type userSignUpQuery = {|
  variables: userSignUpQueryVariables,
  response: userSignUpQueryResponse,
|};
*/


/*
query userSignUpQuery {
  ...Layout
}

fragment Layout on Query {
  me {
    ...Toolbar_me
    ...AutoUpdater_user
    id
    isAdvisor
  }
  ...Toolbar
  ...Monitor
}

fragment Toolbar_me on User {
  id
  username
  displayName
  photoURL
  isAdvisor
}

fragment AutoUpdater_user on User {
  id
  timeZone
}

fragment Toolbar on Query {
  unreadMessageCount
}

fragment Monitor on Query {
  unreadMessageCount
}
*/

const node/*: ConcreteRequest*/ = {
  "kind": "Request",
  "fragment": {
    "kind": "Fragment",
    "name": "userSignUpQuery",
    "type": "Query",
    "metadata": null,
    "argumentDefinitions": [],
    "selections": [
      {
        "kind": "FragmentSpread",
        "name": "Layout",
        "args": null
      }
    ]
  },
  "operation": {
    "kind": "Operation",
    "name": "userSignUpQuery",
    "argumentDefinitions": [],
    "selections": [
      {
        "kind": "LinkedField",
        "alias": null,
        "name": "me",
        "storageKey": null,
        "args": null,
        "concreteType": "User",
        "plural": false,
        "selections": [
          {
            "kind": "ScalarField",
            "alias": null,
            "name": "id",
            "args": null,
            "storageKey": null
          },
          {
            "kind": "ScalarField",
            "alias": null,
            "name": "username",
            "args": null,
            "storageKey": null
          },
          {
            "kind": "ScalarField",
            "alias": null,
            "name": "displayName",
            "args": null,
            "storageKey": null
          },
          {
            "kind": "ScalarField",
            "alias": null,
            "name": "photoURL",
            "args": null,
            "storageKey": null
          },
          {
            "kind": "ScalarField",
            "alias": null,
            "name": "isAdvisor",
            "args": null,
            "storageKey": null
          },
          {
            "kind": "ScalarField",
            "alias": null,
            "name": "timeZone",
            "args": null,
            "storageKey": null
          }
        ]
      },
      {
        "kind": "ScalarField",
        "alias": null,
        "name": "unreadMessageCount",
        "args": null,
        "storageKey": null
      }
    ]
  },
  "params": {
    "operationKind": "query",
    "name": "userSignUpQuery",
    "id": null,
    "text": "query userSignUpQuery {\n  ...Layout\n}\n\nfragment Layout on Query {\n  me {\n    ...Toolbar_me\n    ...AutoUpdater_user\n    id\n    isAdvisor\n  }\n  ...Toolbar\n  ...Monitor\n}\n\nfragment Toolbar_me on User {\n  id\n  username\n  displayName\n  photoURL\n  isAdvisor\n}\n\nfragment AutoUpdater_user on User {\n  id\n  timeZone\n}\n\nfragment Toolbar on Query {\n  unreadMessageCount\n}\n\nfragment Monitor on Query {\n  unreadMessageCount\n}\n",
    "metadata": {}
  }
};
// prettier-ignore
(node/*: any*/).hash = 'ab3ec91fbedf4c22bfaa4b82ac707d31';
module.exports = node;
