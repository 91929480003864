import React from 'react';
import SvgIcon from '@material-ui/core/SvgIcon';

const PrepdupAdvisingLogo = () => (
  <SvgIcon
    aria-labelledby="Prepdup-advising-logo-icon1"
    style={{
      width: '40px',
      height: '40px',
    }}
    viewBox="0 0 40 40"
    role="img"
  >
    <title id="PrepdUp-Advising-Logo1">PrepdUp-Advising-Logo</title>
    <defs>
      <linearGradient
        x1="110.546952%"
        y1="5.82536537%"
        x2="19.082049%"
        y2="92.5585894%"
        id="linearGradient-1"
      >
        <stop stopColor="#DD1A28" offset="0%" />
        <stop stopColor="#C60DB7" offset="100%" />
      </linearGradient>
    </defs>
    <g id="Page-1" stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
      <g id="PrepdupAdvising-Icon-+-Circular">
        <g
          id="g3760"
          transform="translate(20.000000, 20.000001) scale(-1, 1) rotate(-180.000000) translate(-20.000000, -20.000001) translate(0.000000, 0.000001)"
        >
          <g id="g3762" fill="url(#linearGradient-1)">
            <g id="g3764">
              <g id="g3770">
                <g id="g3772">
                  <path
                    d="M0,19.9999995 C0,8.91888867 8.91888867,0 19.9999995,0 C31.0811103,0 39.999999,8.91888867 39.999999,19.9999995 C39.999999,31.0811103 31.0811103,39.999999 19.9999995,39.999999 C8.91888867,39.999999 0,31.0811103 0,19.9999995"
                    id="path3780"
                  />
                </g>
              </g>
            </g>
          </g>
          <g
            id="g3782"
            transform="translate(3.333333, 7.960000)"
            fill="#FFFFFF"
            fillRule="nonzero"
          >
            <path
              d="M16.6666662,23.3333328 L13.4811108,21.4933328 L10.2911109,19.6533328 L7.10555538,17.8122218 L3.91444435,15.9722218 L0.728888871,14.1322219 L3.91444435,12.2922219 L7.10555538,10.4511108 L7.10555538,0.746666648 L8.69777756,1.87888884 L10.2911109,0.746666648 L10.2911109,8.6111109 L7.52555537,10.2088886 L10.2911109,8.6111109 L13.4811108,10.4511108 L10.2911109,12.2922219 L7.10555538,14.1322219 L10.2911109,15.9722218 L13.4811108,17.8122218 L16.6666662,19.6533328 L19.8522217,17.8122218 L23.0422216,15.9722218 L26.2277771,14.1322219 L23.0422216,12.2922219 L19.8522217,10.4511108 L16.6666662,8.6111109 L14.4699996,9.87888864 L11.2844442,8.03777758 L13.4811108,6.77111094 L16.6666662,4.93111099 L19.8522217,6.77111094 L23.0422216,8.6111109 L26.2277771,10.4511108 L29.4188882,12.2922219 L32.6044436,14.1322219 L29.4188882,15.9722218 L26.2277771,17.8122218 L23.0422216,19.6533328 L19.8522217,21.4933328 L16.6666662,23.3333328 Z M16.6666663,15.9722218 L13.4811108,14.1322219 L10.2911109,12.2922219 L13.4811108,10.4511108 L19.8522217,14.1322219 L16.6666663,15.9722218 Z"
              id="path3784"
            />
          </g>
        </g>
      </g>
    </g>
  </SvgIcon>
);

export default PrepdupAdvisingLogo;
