/* @flow */

import React, { useState } from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import Typography from '@material-ui/core/Typography';
import { injectGlobal } from 'styled-components';
import { withStyles } from '@material-ui/core/styles';
import { graphql, createRefetchContainer } from 'react-relay';

import Link from './Link';
import ToolbarWithDrawer from './Toolbar';
import AutoUpdater from './AutoUpdater';
import PhoneVerificationDialog from '../user/PhoneVerificationDialog';
import Snackbar from './Snackbar';
import Facebook from '../icons/FacebookFooterImg';
import Twitter from '../icons/Twitter';
import Monitor from './Monitor';
import { useHistory } from '../hooks';

const Root = styled.div`
  height: 100%;
  flex-grow: 1;
`;

const Container = styled.div`
  margin: 0 auto;
  padding-left: 16px;
  padding-right: 16px;
`;

injectGlobal`
  html {
    background-color: #fafafa;
  }

  html,
  body,
  #root {
    height: 100%;
  }

  body {
    padding: 0;
    margin: 0;
    font-family: sans-serif;
  }
`;

const Footer = styled.div`
  padding-top: 2em;
  padding-bottom: 2em;
  color: rgba(0, 0, 0, 0.2);
  font-size: 0.875rem;

  > a {
    color: rgba(0, 0, 0, 0.4);
    text-decoration: none;
  }

  > a:hover {
    color: rgba(0, 0, 0, 0.6);
    text-decoration: underline;
  }
`;

const styles = theme => ({
  customContainer: {
    minHeight: 'calc(100vh - 68px)',
    paddingTop: 64,
    boxSizing: 'border-box',
    [theme.breakpoints.down('sm')]: {
      paddingTop: 48,
    },
  },
  root: {
    minHeight: '100vh',
    position: 'relative',
  },
  footer: {
    paddingTop: 7,
    marginTop: 20,
    paddingBottom: 7,
    paddingLeft: 25,
    paddingRight: 25,
    height: theme.spacing.unit * 6,
    backgroundColor: theme.palette.grey[200],
    boxSizing: 'border-box',
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
  },
  textGrey: {
    color: theme.palette.text.secondary,
  },
  linkGrey: {
    color: theme.palette.text.secondary,
    textDecoration: 'none',
    '&:hover': {
      color: theme.palette.grey[700],
      textDecoration: 'underline',
    },
  },
  iconContainer: {
    width: 60,
    display: 'flex',
    justifyContent: 'space-between',
  },
  icon: {
    maxWidth: 24,
    maxHeight: 24,
  },
  small: {
    maxWidth: theme.contentWidth.small,
  },
  medium: {
    maxWidth: theme.contentWidth.medium,
  },
  wide: {
    maxWidth: theme.contentWidth.wide,
  },
  wideLayout: {
    backgroundColor: theme.palette.background.paper,
    height: 'auto',
  },
});

function openLink(url) {
  var win = window.open(url, '_blank');
  win.focus();
}

function Layout(props) {
  const history = useHistory();
  const [state, setState] = useState({
    drawerOpen: false,
  });

  const { classes, style, data, children, contentWidth, hideLinks } = props;
  const { drawerOpen } = state;
  const contentClass = classes[contentWidth];
  const rootClass =
    contentWidth === 'wide'
      ? `${classes.root} ${classes.wideLayout}`
      : classes.root;

  function toggleDrawer() {
    setState({ drawerOpen: !state.drawerOpen });
  }

  return (
    <Root style={style} className={rootClass}>
      <ToolbarWithDrawer
        data={data}
        me={data.me}
        onMenuClick={toggleDrawer}
        open={drawerOpen}
        hideLinks={hideLinks}
      />
      {data.me && !data.me.isAdvisor && (
        <PhoneVerificationDialog id={data.me.id} history={history} />
      )}
      <Container className={`${classes.customContainer} ${contentClass}`}>
        {children}
      </Container>
      <Footer className={classes.footer}>
        <div>
          {!hideLinks && (
            <Link className={classes.linkGrey} href="/terms">
              <Typography className={classes.linkGrey}>
                Terms & Privacy
              </Typography>
            </Link>
          )}
        </div>
        <div className={classes.iconContainer}>
          <div className={classes.icon}>
            <Facebook
              onClick={() => {
                openLink('https://www.facebook.com/iamprepdup/');
              }}
            />
          </div>
          <div className={classes.icon}>
            <Twitter
              onClick={() => {
                openLink('https://twitter.com/Prepdup');
              }}
            />
          </div>
        </div>
      </Footer>
      <AutoUpdater user={data.me} />
      <Monitor data={props.data} />
      <Snackbar />
    </Root>
  );
}

Layout.defaultProps = {
  contentWidth: 'small',
  hideLinks: false,
};

Layout.propTypes = {
  contentWidth: PropTypes.oneOf(['small', 'medium', 'wide']).isRequired,
};

export default withStyles(styles)(
  createRefetchContainer(
    Layout,
    graphql`
      fragment Layout on Query {
        me {
          ...Toolbar_me
          ...AutoUpdater_user
          id
          isAdvisor
        }
        ...Toolbar
        ...Monitor
      }
    `,
  ),
);
