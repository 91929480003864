/**
 * @flow
 * @relayHash 92f17a98f30720d987eeda2480a5b665
 */

/* eslint-disable */

'use strict';

/*::
import type { ConcreteRequest } from 'relay-runtime';
type Layout$ref = any;
type Thread$ref = any;
type Thread_user$ref = any;
export type messagesThreadQueryVariables = {|
  username: string,
  duration?: ?number,
|};
export type messagesThreadQueryResponse = {|
  +user: ?{|
    +displayName: ?string,
    +$fragmentRefs: Thread_user$ref,
  |},
  +$fragmentRefs: Layout$ref & Thread$ref,
|};
export type messagesThreadQuery = {|
  variables: messagesThreadQueryVariables,
  response: messagesThreadQueryResponse,
|};
*/


/*
query messagesThreadQuery(
  $username: String!
  $duration: Int
) {
  ...Layout
  ...Thread
  user(username: $username) {
    displayName
    ...Thread_user
    id
  }
}

fragment Layout on Query {
  me {
    ...Toolbar_me
    ...AutoUpdater_user
    id
    isAdvisor
  }
  ...Toolbar
  ...Monitor
}

fragment Thread on Query {
  connection(username: $username) {
    id
    lastMessageId
    lastMessageText
    lastMessageAuthorId
    connectionType
    createdAt
    updatedAt
  }
  me {
    id
    isAdvisor
    photoURL
    timeZone
    phoneVerified
  }
  requests(username: $username) {
    edges {
      node {
        text
        updatedAt
        responses(username: $username) {
          text
          updatedAt
          id
        }
        id
      }
    }
  }
}

fragment Thread_user on User {
  id
  photoURL
  displayName
  linkedin
  about
  ...AvailableSlotsDialog_user_KlYE7
}

fragment AvailableSlotsDialog_user_KlYE7 on User {
  id
  username
  availableSlots(duration: $duration)
}

fragment Toolbar_me on User {
  id
  username
  displayName
  photoURL
  isAdvisor
}

fragment AutoUpdater_user on User {
  id
  timeZone
}

fragment Toolbar on Query {
  unreadMessageCount
}

fragment Monitor on Query {
  unreadMessageCount
}
*/

const node/*: ConcreteRequest*/ = (function(){
var v0 = [
  {
    "kind": "LocalArgument",
    "name": "username",
    "type": "String!",
    "defaultValue": null
  },
  {
    "kind": "LocalArgument",
    "name": "duration",
    "type": "Int",
    "defaultValue": null
  }
],
v1 = [
  {
    "kind": "Variable",
    "name": "username",
    "variableName": "username",
    "type": "String!"
  }
],
v2 = {
  "kind": "ScalarField",
  "alias": null,
  "name": "displayName",
  "args": null,
  "storageKey": null
},
v3 = {
  "kind": "ScalarField",
  "alias": null,
  "name": "id",
  "args": null,
  "storageKey": null
},
v4 = {
  "kind": "ScalarField",
  "alias": null,
  "name": "username",
  "args": null,
  "storageKey": null
},
v5 = {
  "kind": "ScalarField",
  "alias": null,
  "name": "photoURL",
  "args": null,
  "storageKey": null
},
v6 = {
  "kind": "ScalarField",
  "alias": null,
  "name": "updatedAt",
  "args": null,
  "storageKey": null
},
v7 = [
  {
    "kind": "Variable",
    "name": "username",
    "variableName": "username",
    "type": "String"
  }
],
v8 = {
  "kind": "ScalarField",
  "alias": null,
  "name": "text",
  "args": null,
  "storageKey": null
};
return {
  "kind": "Request",
  "fragment": {
    "kind": "Fragment",
    "name": "messagesThreadQuery",
    "type": "Query",
    "metadata": null,
    "argumentDefinitions": (v0/*: any*/),
    "selections": [
      {
        "kind": "FragmentSpread",
        "name": "Layout",
        "args": null
      },
      {
        "kind": "FragmentSpread",
        "name": "Thread",
        "args": null
      },
      {
        "kind": "LinkedField",
        "alias": null,
        "name": "user",
        "storageKey": null,
        "args": (v1/*: any*/),
        "concreteType": "User",
        "plural": false,
        "selections": [
          (v2/*: any*/),
          {
            "kind": "FragmentSpread",
            "name": "Thread_user",
            "args": null
          }
        ]
      }
    ]
  },
  "operation": {
    "kind": "Operation",
    "name": "messagesThreadQuery",
    "argumentDefinitions": (v0/*: any*/),
    "selections": [
      {
        "kind": "LinkedField",
        "alias": null,
        "name": "me",
        "storageKey": null,
        "args": null,
        "concreteType": "User",
        "plural": false,
        "selections": [
          (v3/*: any*/),
          (v4/*: any*/),
          (v2/*: any*/),
          (v5/*: any*/),
          {
            "kind": "ScalarField",
            "alias": null,
            "name": "isAdvisor",
            "args": null,
            "storageKey": null
          },
          {
            "kind": "ScalarField",
            "alias": null,
            "name": "timeZone",
            "args": null,
            "storageKey": null
          },
          {
            "kind": "ScalarField",
            "alias": null,
            "name": "phoneVerified",
            "args": null,
            "storageKey": null
          }
        ]
      },
      {
        "kind": "ScalarField",
        "alias": null,
        "name": "unreadMessageCount",
        "args": null,
        "storageKey": null
      },
      {
        "kind": "LinkedField",
        "alias": null,
        "name": "connection",
        "storageKey": null,
        "args": (v1/*: any*/),
        "concreteType": "Connection",
        "plural": false,
        "selections": [
          (v3/*: any*/),
          {
            "kind": "ScalarField",
            "alias": null,
            "name": "lastMessageId",
            "args": null,
            "storageKey": null
          },
          {
            "kind": "ScalarField",
            "alias": null,
            "name": "lastMessageText",
            "args": null,
            "storageKey": null
          },
          {
            "kind": "ScalarField",
            "alias": null,
            "name": "lastMessageAuthorId",
            "args": null,
            "storageKey": null
          },
          {
            "kind": "ScalarField",
            "alias": null,
            "name": "connectionType",
            "args": null,
            "storageKey": null
          },
          {
            "kind": "ScalarField",
            "alias": null,
            "name": "createdAt",
            "args": null,
            "storageKey": null
          },
          (v6/*: any*/)
        ]
      },
      {
        "kind": "LinkedField",
        "alias": null,
        "name": "requests",
        "storageKey": null,
        "args": (v7/*: any*/),
        "concreteType": "RequestConnection",
        "plural": false,
        "selections": [
          {
            "kind": "LinkedField",
            "alias": null,
            "name": "edges",
            "storageKey": null,
            "args": null,
            "concreteType": "RequestEdge",
            "plural": true,
            "selections": [
              {
                "kind": "LinkedField",
                "alias": null,
                "name": "node",
                "storageKey": null,
                "args": null,
                "concreteType": "Request",
                "plural": false,
                "selections": [
                  (v8/*: any*/),
                  (v6/*: any*/),
                  {
                    "kind": "LinkedField",
                    "alias": null,
                    "name": "responses",
                    "storageKey": null,
                    "args": (v7/*: any*/),
                    "concreteType": "Response",
                    "plural": true,
                    "selections": [
                      (v8/*: any*/),
                      (v6/*: any*/),
                      (v3/*: any*/)
                    ]
                  },
                  (v3/*: any*/)
                ]
              }
            ]
          }
        ]
      },
      {
        "kind": "LinkedField",
        "alias": null,
        "name": "user",
        "storageKey": null,
        "args": (v1/*: any*/),
        "concreteType": "User",
        "plural": false,
        "selections": [
          (v2/*: any*/),
          (v3/*: any*/),
          (v5/*: any*/),
          {
            "kind": "ScalarField",
            "alias": null,
            "name": "linkedin",
            "args": null,
            "storageKey": null
          },
          {
            "kind": "ScalarField",
            "alias": null,
            "name": "about",
            "args": null,
            "storageKey": null
          },
          (v4/*: any*/),
          {
            "kind": "ScalarField",
            "alias": null,
            "name": "availableSlots",
            "args": [
              {
                "kind": "Variable",
                "name": "duration",
                "variableName": "duration",
                "type": "Int"
              }
            ],
            "storageKey": null
          }
        ]
      }
    ]
  },
  "params": {
    "operationKind": "query",
    "name": "messagesThreadQuery",
    "id": null,
    "text": "query messagesThreadQuery(\n  $username: String!\n  $duration: Int\n) {\n  ...Layout\n  ...Thread\n  user(username: $username) {\n    displayName\n    ...Thread_user\n    id\n  }\n}\n\nfragment Layout on Query {\n  me {\n    ...Toolbar_me\n    ...AutoUpdater_user\n    id\n    isAdvisor\n  }\n  ...Toolbar\n  ...Monitor\n}\n\nfragment Thread on Query {\n  connection(username: $username) {\n    id\n    lastMessageId\n    lastMessageText\n    lastMessageAuthorId\n    connectionType\n    createdAt\n    updatedAt\n  }\n  me {\n    id\n    isAdvisor\n    photoURL\n    timeZone\n    phoneVerified\n  }\n  requests(username: $username) {\n    edges {\n      node {\n        text\n        updatedAt\n        responses(username: $username) {\n          text\n          updatedAt\n          id\n        }\n        id\n      }\n    }\n  }\n}\n\nfragment Thread_user on User {\n  id\n  photoURL\n  displayName\n  linkedin\n  about\n  ...AvailableSlotsDialog_user_KlYE7\n}\n\nfragment AvailableSlotsDialog_user_KlYE7 on User {\n  id\n  username\n  availableSlots(duration: $duration)\n}\n\nfragment Toolbar_me on User {\n  id\n  username\n  displayName\n  photoURL\n  isAdvisor\n}\n\nfragment AutoUpdater_user on User {\n  id\n  timeZone\n}\n\nfragment Toolbar on Query {\n  unreadMessageCount\n}\n\nfragment Monitor on Query {\n  unreadMessageCount\n}\n",
    "metadata": {}
  }
};
})();
// prettier-ignore
(node/*: any*/).hash = '041bd315ac363fad62227df96f0fd304';
module.exports = node;
