/**
 * @flow
 */

/* eslint-disable */

'use strict';

/*::
import type { ReaderFragment } from 'relay-runtime';
type AutoUpdater_user$ref = any;
type Monitor$ref = any;
type Toolbar$ref = any;
type Toolbar_me$ref = any;
import type { FragmentReference } from "relay-runtime";
declare export opaque type Layout$ref: FragmentReference;
export type Layout = {|
  +me: ?{|
    +id: string,
    +isAdvisor: ?boolean,
    +$fragmentRefs: Toolbar_me$ref & AutoUpdater_user$ref,
  |},
  +$fragmentRefs: Toolbar$ref & Monitor$ref,
  +$refType: Layout$ref,
|};
*/


const node/*: ReaderFragment*/ = {
  "kind": "Fragment",
  "name": "Layout",
  "type": "Query",
  "metadata": null,
  "argumentDefinitions": [],
  "selections": [
    {
      "kind": "LinkedField",
      "alias": null,
      "name": "me",
      "storageKey": null,
      "args": null,
      "concreteType": "User",
      "plural": false,
      "selections": [
        {
          "kind": "FragmentSpread",
          "name": "Toolbar_me",
          "args": null
        },
        {
          "kind": "FragmentSpread",
          "name": "AutoUpdater_user",
          "args": null
        },
        {
          "kind": "ScalarField",
          "alias": null,
          "name": "id",
          "args": null,
          "storageKey": null
        },
        {
          "kind": "ScalarField",
          "alias": null,
          "name": "isAdvisor",
          "args": null,
          "storageKey": null
        }
      ]
    },
    {
      "kind": "FragmentSpread",
      "name": "Toolbar",
      "args": null
    },
    {
      "kind": "FragmentSpread",
      "name": "Monitor",
      "args": null
    }
  ]
};
// prettier-ignore
(node/*: any*/).hash = '79c58256d3d5bbecd4e069a237058318';
module.exports = node;
