/* @flow */

import React from 'react';
import { ReactRelayContext } from 'react-relay';
import { ViewMode } from './constants';

const history = { location: { pathname: '/' } };

export const ConfigContext = React.createContext({});
export const HistoryContext = React.createContext(history);
export const ViewModeContext = React.createContext(ViewMode.SEEKER);
export const ResetContext = React.createContext(() => {});

export function useConfig() {
  return React.useContext(ConfigContext);
}

export function useHistory() {
  return React.useContext(HistoryContext);
}

export function useViewMode() {
  return React.useContext(ViewModeContext);
}

export function useRelay() {
  return React.useContext(ReactRelayContext);
}

export function useReset() {
  return React.useContext(ResetContext);
}
