import React from 'react';
import loading from '../utils/loading';
import LinearProgressBar from '@material-ui/core/LinearProgress';
import { withStyles } from '@material-ui/core/styles';

const styles = theme => ({
  linearColorPrimary: {
    backgroundColor: '#f3e5f5',
    height: '3px !important',
    position: 'absolute',
    width: '100%',
  },
  linearBarColorPrimary: {
    backgroundColor: '#9c27b0',
    height: '3px !important',
  },
});

class LoadingBar extends React.Component {
  state = { loading: false };

  componentDidMount() {
    this.unlisten = loading.listen(this.handleLoading);
  }

  componentWillUnmount() {
    this.unlisten();
  }

  handleLoading = loading => {
    this.setState({ loading });
  };

  render() {
    const { classes } = this.props;
    return (
      this.state.loading && (
        <LinearProgressBar
          variant="indeterminate"
          classes={{
            colorPrimary: classes.linearColorPrimary,
            barColorPrimary: classes.linearBarColorPrimary,
          }}
        />
      )
    );
  }
}
export default withStyles(styles)(LoadingBar);
