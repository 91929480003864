/**
 * @flow
 * @relayHash 9cd6f1da2323f3b92709fd1fbcad4e78
 */

/* eslint-disable */

'use strict';

/*::
import type { ConcreteRequest } from 'relay-runtime';
type AdvisorRequestsNavigation$ref = any;
type AdvisorResponses$ref = any;
type Layout$ref = any;
export type advisorResponsesQueryVariables = {||};
export type advisorResponsesQueryResponse = {|
  +$fragmentRefs: Layout$ref & AdvisorRequestsNavigation$ref & AdvisorResponses$ref
|};
export type advisorResponsesQuery = {|
  variables: advisorResponsesQueryVariables,
  response: advisorResponsesQueryResponse,
|};
*/


/*
query advisorResponsesQuery {
  ...Layout
  ...AdvisorRequestsNavigation
  ...AdvisorResponses
}

fragment Layout on Query {
  me {
    ...Toolbar_me
    ...AutoUpdater_user
    id
    isAdvisor
  }
  ...Toolbar
  ...Monitor
}

fragment AdvisorRequestsNavigation on Query {
  responses(filter: "is:mine") {
    totalCount
  }
}

fragment AdvisorResponses on Query {
  responses(filter: "is:mine") {
    totalCount
    edges {
      node {
        ...AdvisorResponseCard
        id
      }
    }
  }
}

fragment AdvisorResponseCard on Response {
  id
  text(truncate: 180)
  quote20m
  quote50m
  createdAt(format: "MMM Do, YYYY")
  request {
    author {
      displayName
      photoURL
      username
      id
    }
    text(truncate: 75)
    id
  }
}

fragment Toolbar_me on User {
  id
  username
  displayName
  photoURL
  isAdvisor
}

fragment AutoUpdater_user on User {
  id
  timeZone
}

fragment Toolbar on Query {
  unreadMessageCount
}

fragment Monitor on Query {
  unreadMessageCount
}
*/

const node/*: ConcreteRequest*/ = (function(){
var v0 = {
  "kind": "ScalarField",
  "alias": null,
  "name": "id",
  "args": null,
  "storageKey": null
},
v1 = {
  "kind": "ScalarField",
  "alias": null,
  "name": "username",
  "args": null,
  "storageKey": null
},
v2 = {
  "kind": "ScalarField",
  "alias": null,
  "name": "displayName",
  "args": null,
  "storageKey": null
},
v3 = {
  "kind": "ScalarField",
  "alias": null,
  "name": "photoURL",
  "args": null,
  "storageKey": null
};
return {
  "kind": "Request",
  "fragment": {
    "kind": "Fragment",
    "name": "advisorResponsesQuery",
    "type": "Query",
    "metadata": null,
    "argumentDefinitions": [],
    "selections": [
      {
        "kind": "FragmentSpread",
        "name": "Layout",
        "args": null
      },
      {
        "kind": "FragmentSpread",
        "name": "AdvisorRequestsNavigation",
        "args": null
      },
      {
        "kind": "FragmentSpread",
        "name": "AdvisorResponses",
        "args": null
      }
    ]
  },
  "operation": {
    "kind": "Operation",
    "name": "advisorResponsesQuery",
    "argumentDefinitions": [],
    "selections": [
      {
        "kind": "LinkedField",
        "alias": null,
        "name": "me",
        "storageKey": null,
        "args": null,
        "concreteType": "User",
        "plural": false,
        "selections": [
          (v0/*: any*/),
          (v1/*: any*/),
          (v2/*: any*/),
          (v3/*: any*/),
          {
            "kind": "ScalarField",
            "alias": null,
            "name": "isAdvisor",
            "args": null,
            "storageKey": null
          },
          {
            "kind": "ScalarField",
            "alias": null,
            "name": "timeZone",
            "args": null,
            "storageKey": null
          }
        ]
      },
      {
        "kind": "ScalarField",
        "alias": null,
        "name": "unreadMessageCount",
        "args": null,
        "storageKey": null
      },
      {
        "kind": "LinkedField",
        "alias": null,
        "name": "responses",
        "storageKey": "responses(filter:\"is:mine\")",
        "args": [
          {
            "kind": "Literal",
            "name": "filter",
            "value": "is:mine",
            "type": "String"
          }
        ],
        "concreteType": "ResponseConnection",
        "plural": false,
        "selections": [
          {
            "kind": "ScalarField",
            "alias": null,
            "name": "totalCount",
            "args": null,
            "storageKey": null
          },
          {
            "kind": "LinkedField",
            "alias": null,
            "name": "edges",
            "storageKey": null,
            "args": null,
            "concreteType": "ResponseEdge",
            "plural": true,
            "selections": [
              {
                "kind": "LinkedField",
                "alias": null,
                "name": "node",
                "storageKey": null,
                "args": null,
                "concreteType": "Response",
                "plural": false,
                "selections": [
                  (v0/*: any*/),
                  {
                    "kind": "ScalarField",
                    "alias": null,
                    "name": "text",
                    "args": [
                      {
                        "kind": "Literal",
                        "name": "truncate",
                        "value": 180,
                        "type": "Int"
                      }
                    ],
                    "storageKey": "text(truncate:180)"
                  },
                  {
                    "kind": "ScalarField",
                    "alias": null,
                    "name": "quote20m",
                    "args": null,
                    "storageKey": null
                  },
                  {
                    "kind": "ScalarField",
                    "alias": null,
                    "name": "quote50m",
                    "args": null,
                    "storageKey": null
                  },
                  {
                    "kind": "ScalarField",
                    "alias": null,
                    "name": "createdAt",
                    "args": [
                      {
                        "kind": "Literal",
                        "name": "format",
                        "value": "MMM Do, YYYY",
                        "type": "String"
                      }
                    ],
                    "storageKey": "createdAt(format:\"MMM Do, YYYY\")"
                  },
                  {
                    "kind": "LinkedField",
                    "alias": null,
                    "name": "request",
                    "storageKey": null,
                    "args": null,
                    "concreteType": "Request",
                    "plural": false,
                    "selections": [
                      {
                        "kind": "LinkedField",
                        "alias": null,
                        "name": "author",
                        "storageKey": null,
                        "args": null,
                        "concreteType": "User",
                        "plural": false,
                        "selections": [
                          (v2/*: any*/),
                          (v3/*: any*/),
                          (v1/*: any*/),
                          (v0/*: any*/)
                        ]
                      },
                      {
                        "kind": "ScalarField",
                        "alias": null,
                        "name": "text",
                        "args": [
                          {
                            "kind": "Literal",
                            "name": "truncate",
                            "value": 75,
                            "type": "Int"
                          }
                        ],
                        "storageKey": "text(truncate:75)"
                      },
                      (v0/*: any*/)
                    ]
                  }
                ]
              }
            ]
          }
        ]
      }
    ]
  },
  "params": {
    "operationKind": "query",
    "name": "advisorResponsesQuery",
    "id": null,
    "text": "query advisorResponsesQuery {\n  ...Layout\n  ...AdvisorRequestsNavigation\n  ...AdvisorResponses\n}\n\nfragment Layout on Query {\n  me {\n    ...Toolbar_me\n    ...AutoUpdater_user\n    id\n    isAdvisor\n  }\n  ...Toolbar\n  ...Monitor\n}\n\nfragment AdvisorRequestsNavigation on Query {\n  responses(filter: \"is:mine\") {\n    totalCount\n  }\n}\n\nfragment AdvisorResponses on Query {\n  responses(filter: \"is:mine\") {\n    totalCount\n    edges {\n      node {\n        ...AdvisorResponseCard\n        id\n      }\n    }\n  }\n}\n\nfragment AdvisorResponseCard on Response {\n  id\n  text(truncate: 180)\n  quote20m\n  quote50m\n  createdAt(format: \"MMM Do, YYYY\")\n  request {\n    author {\n      displayName\n      photoURL\n      username\n      id\n    }\n    text(truncate: 75)\n    id\n  }\n}\n\nfragment Toolbar_me on User {\n  id\n  username\n  displayName\n  photoURL\n  isAdvisor\n}\n\nfragment AutoUpdater_user on User {\n  id\n  timeZone\n}\n\nfragment Toolbar on Query {\n  unreadMessageCount\n}\n\nfragment Monitor on Query {\n  unreadMessageCount\n}\n",
    "metadata": {}
  }
};
})();
// prettier-ignore
(node/*: any*/).hash = '3c00fb4af26eb0c394e2f6a358565296';
module.exports = node;
