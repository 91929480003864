export { default as errors } from './errors';
export { default as isBrowserSupported } from './isBrowserSupported';
export { default as openWindow } from './openWindow';
export { onScroll, getScrollPosition } from './scrolling';
export { createInterval } from './timer';
export * from './gtag';
export * from './viewMode';

const language =
  typeof navigator === 'undefined'
    ? 'en-US'
    : (navigator.languages && navigator.languages[0]) || // Chrome / Firefox
      navigator.language || // All browsers
      navigator.userLanguage;

/**
 * This function would convert utc datetime to local datetime
 * @deprecated Deprecated in favor of format(date, options). See below.
 */
export function getFormattedDateTime(date, num) {
  return getFormattedDate(date, num) + ' ' + getFormattedTime(date);
}

export function getFormattedTime(date) {
  return new Date(date).toLocaleTimeString(language, {
    hour: 'numeric',
    minute: 'numeric',
  });
}

/**
 * @deprecated Deprecated in favor of format(date, options). See below.
 */
export function getFormattedDate(dt, num) {
  let format = num ? 'numeric' : 'short';
  let date = new Date(dt);
  let formattedDate = date.toLocaleDateString(language, {
    weekday: 'short',
    month: format,
    day: 'numeric',
    year: 'numeric',
  });
  return format
    ? formattedDate
    : formattedDate.replace(date.getDate(), getOrdinalNum(date.getDate()));
}

function getOrdinalNum(n) {
  return (
    n +
    (n > 0
      ? ['th', 'st', 'nd', 'rd'][(n > 3 && n < 21) || n % 10 > 3 ? 0 : n % 10]
      : '')
  );
}

/* 'LL' => January 23, 2019
   'll' => Jan 23, 2019
   'LLL' => January 23, 2019, 3:39 PM
   'lll' => Jan 23, 2019, 3:39 PM
  */

export function format(date, options) {
  if (!date) return date;
  const x = typeof date === 'string' ? new Date(date) : date;
  switch (options) {
    case 'LT':
      return x.toLocaleString(language, {
        hour: 'numeric',
        minute: 'numeric',
      });
    case 'lll':
      return x.toLocaleString(language, {
        month: 'short',
        day: 'numeric',
        year: 'numeric',
        hour: 'numeric',
        minute: 'numeric',
      });
    case 'ddd, ll':
      return x.toLocaleString(language, {
        weekday: 'short',
        month: 'short',
        day: 'numeric',
        year: 'numeric',
      });
    case 'MMM D':
      return x.toLocaleString(language, {
        month: 'short',
        day: 'numeric',
      });
    case 'YYYY':
      return x.toLocaleString(language, {
        year: 'numeric',
      });
    case 'L':
      return x.toLocaleDateString(language, {
        day: 'numeric',
        month: 'numeric',
        year: 'numeric',
      });
    default:
      throw new Error('Date/time format not supported.');
  }
}

export function isPartner() {
  return document.cookie.split(';').some(x => x.trim() === 'isPartner=true');
}
