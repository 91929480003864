/**
 * Default settings for a new interval.
 */
const defaults = {
  interval: null,
  callbacks: new Set(),
  timeout: 20000, // 10sec
};

/**
 * The list of known intervals and timeout settings.
 */
const intervals = {
  messages: { ...defaults },
};

/**
 * Creates a new interval that will execute the provided callback
 * function every X seconds. For example:
 *
 *   componentDidMount() {
 *     this.dispose = createInterval('messages', () => this.props.refetch());
 *   }
 *
 *   componentWillUnmount() {
 *     this.dispose();
 *   }
 */
export function createInterval(name, cb) {
  if (!intervals[name]) {
    intervals[name] = { ...defaults };
  }

  intervals[name].callbacks.add(cb);

  if (intervals[name].callbacks.size) {
    intervals[name].interval = setInterval(cb, intervals[name].timeout);
  }

  return () => {
    intervals[name].callbacks.delete(cb);
    if (!intervals[name].callbacks.size) {
      clearInterval(intervals[name].interval);
      intervals[name].interval = null;
    }
  };
}
