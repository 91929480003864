/**
 * @flow
 * @relayHash c575e6be00a93ec50e281e4ac7d38a48
 */

/* eslint-disable */

'use strict';

/*::
import type { ConcreteRequest } from 'relay-runtime';
type Login$ref = any;
export type userLoginQueryVariables = {||};
export type userLoginQueryResponse = {|
  +$fragmentRefs: Login$ref
|};
export type userLoginQuery = {|
  variables: userLoginQueryVariables,
  response: userLoginQueryResponse,
|};
*/


/*
query userLoginQuery {
  ...Login
}

fragment Login on Query {
  me {
    isAdvisor
    id
  }
}
*/

const node/*: ConcreteRequest*/ = {
  "kind": "Request",
  "fragment": {
    "kind": "Fragment",
    "name": "userLoginQuery",
    "type": "Query",
    "metadata": null,
    "argumentDefinitions": [],
    "selections": [
      {
        "kind": "FragmentSpread",
        "name": "Login",
        "args": null
      }
    ]
  },
  "operation": {
    "kind": "Operation",
    "name": "userLoginQuery",
    "argumentDefinitions": [],
    "selections": [
      {
        "kind": "LinkedField",
        "alias": null,
        "name": "me",
        "storageKey": null,
        "args": null,
        "concreteType": "User",
        "plural": false,
        "selections": [
          {
            "kind": "ScalarField",
            "alias": null,
            "name": "isAdvisor",
            "args": null,
            "storageKey": null
          },
          {
            "kind": "ScalarField",
            "alias": null,
            "name": "id",
            "args": null,
            "storageKey": null
          }
        ]
      }
    ]
  },
  "params": {
    "operationKind": "query",
    "name": "userLoginQuery",
    "id": null,
    "text": "query userLoginQuery {\n  ...Login\n}\n\nfragment Login on Query {\n  me {\n    isAdvisor\n    id\n  }\n}\n",
    "metadata": {}
  }
};
// prettier-ignore
(node/*: any*/).hash = 'd485576b4e491125c8deb7a17e255393';
module.exports = node;
