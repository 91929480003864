import React from 'react';
import Link from './Link';
import { useViewMode } from '../hooks';
import { ViewMode, AdvisorUriPrefix } from '../constants';

function ViewLink(props) {
  const viewMode = useViewMode();
  const href =
    viewMode === ViewMode.ADVISOR
      ? `${AdvisorUriPrefix}${props.href}`
      : props.href;

  return <Link {...props} href={href} />;
}

export default ViewLink;
