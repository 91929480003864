import { ViewMode, StaticPages, AdvisorUriPrefix } from '../constants';

/**
 * Resolves the current view mode (user/advisor) based on the provided URL path
 * and saves it into browser's session, but only if the current URL is not one
 * of the generic pages such as About Us, Terms of Use, etc.
 */
export function resolveViewMode(pathname, initialViewMode) {
  if (initialViewMode) {
    if (pathname !== '/') {
      window.sessionStorage.setItem('viewMode', initialViewMode);
    }
    return initialViewMode;
  }

  if (StaticPages.some(x => pathname === x || pathname.startsWith(`${x}/`))) {
    return (
      ViewMode[window.sessionStorage.getItem('viewMode')] || ViewMode.SEEKER
    );
  }

  const viewMode =
    pathname === AdvisorUriPrefix || pathname.startsWith(`${AdvisorUriPrefix}/`)
      ? ViewMode.ADVISOR
      : ViewMode.SEEKER;

  if (pathname !== '/' || ViewMode[window.sessionStorage.getItem('viewMode')]) {
    window.sessionStorage.setItem('viewMode', viewMode);
  }

  return viewMode;
}

export function resetViewMode() {
  window.sessionStorage.removeItem('viewMode');
}

export function toggleViewMode(history, currentViewMode) {
  // Toggle the current view mode from ADVISOR to SEEKER or vice versa
  switch (currentViewMode) {
    case ViewMode.ADVISOR:
      window.sessionStorage.setItem('viewMode', ViewMode.SEEKER);
      history.push({ ...history.location, pathname: '/' });
      break;
    case ViewMode.SEEKER:
      window.sessionStorage.setItem('viewMode', ViewMode.ADVISOR);
      history.push({ ...history.location, pathname: AdvisorUriPrefix });
      break;
    default:
      throw new Error();
  }
}
