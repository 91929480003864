function isRTCPeerConnectionSupported() {
  return (
    typeof RTCPeerConnection !== 'undefined' ||
    typeof webkitRTCPeerConnection !== 'undefined' ||
    typeof mozRTCPeerConnection !== 'undefined'
  );
}

function isGetUserMediaSupported() {
  return (
    !!(navigator.mediaDevices && navigator.mediaDevices.getUserMedia) ||
    !!navigator.getUserMedia ||
    !!navigator.webkitGetUserMedia ||
    !!navigator.mozGetUserMedia
  );
}

/**
 * Check if the browser is supported by Twilio Video API.
 * https://www.twilio.com/docs/video/javascript#supported-browsers
 */
function isBrowserSupported() {
  return (
    // Pass the check in a non-browser environment (during SSR).
    typeof window === 'undefined' ||
    (isRTCPeerConnectionSupported() &&
      isGetUserMediaSupported() &&
      !navigator.userAgent.includes('Edge'))
  );
}

export default isBrowserSupported;
