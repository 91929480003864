import { useEffect } from 'react';
import { graphql, createRefetchContainer } from 'react-relay';

/**
 * This component is designed to fetch new data from the API every X seconds.
 * Things like "unread message count" etc.
 */
function Monitor(props) {
  useEffect(() => {
    const interval = setInterval(() => {
      props.relay.refetch();
    }, 20000);
    return () => {
      clearInterval(interval);
    };
  }, []);
  return null;
}

export default createRefetchContainer(
  Monitor,
  graphql`
    fragment Monitor on Query {
      unreadMessageCount
    }
  `,
  graphql`
    query MonitorQuery {
      ...Monitor
    }
  `,
);
