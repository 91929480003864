/**
 * Created by Vivek.dwivedi on 1/12/2019.
 */
import React from 'react';

const FacebookFooterImg = props => (
  <svg width={25} height={25} {...props}>
    <title>Facebook icon</title>
    <path
      d="M20.92.4H3.952A3.52 3.52 0 0 0 .436 3.918v16.968a3.52 3.52 0 0 0 3.516 3.516h7.078v-8.484H8.217v-4.22h2.813V8.839a4.223 4.223 0 0 1 4.218-4.218h4.266v4.218h-4.266v2.86h4.266l-.703 4.219h-3.563V24.4h5.672a3.52 3.52 0 0 0 3.516-3.516V3.917A3.52 3.52 0 0 0 20.92.4z"
      fill="#969696"
      fillRule="evenodd"
    />
  </svg>
);

export default FacebookFooterImg;
