/* @flow */

import React from 'react';
import { graphql } from 'relay-runtime';
import Layout from '../common/Layout';

export default [
  {
    path: ['/messages', '/advising/messages'],
    query: graphql`
      query messagesQuery {
        ...Layout
        ...Messages
      }
    `,
    components: () => [import(/* webpackChunkName: 'messages' */ './Messages')],
    render: ([Messages], data) => ({
      title: 'Messages • Prepdup',
      component: (
        <Layout data={data}>
          <Messages data={data} />
        </Layout>
      ),
      chunks: ['messages'],
    }),
  },
  {
    path: ['/messages/:username', '/advising/messages/:username'],
    query: graphql`
      query messagesThreadQuery($username: String!, $duration: Int) {
        ...Layout
        ...Thread
        user(username: $username) {
          displayName
          ...Thread_user
        }
      }
    `,
    components: () => [import(/* webpackChunkName: 'messages' */ './Thread')],
    render: ([Thread], data) => {
      if (!data.user) return null;
      return {
        title: `Chat with ${data.user.displayName} • Prepdup`,
        component: (
          <Layout data={data} style={{ backgroundColor: 'white' }}>
            <Thread data={data} user={data.user} />
          </Layout>
        ),
        chunks: ['messages'],
      };
    },
  },
];
