import React from 'react';
import Button from '@material-ui/core/Button';
import TextField from '@material-ui/core/TextField';
import FormControl from '@material-ui/core/FormControl';
import Dialog from '@material-ui/core/Dialog';
import DialogTitle from '@material-ui/core/DialogTitle';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogActions from '@material-ui/core/DialogActions';
import Typography from '@material-ui/core/Typography';
import { withStyles } from '@material-ui/core/styles';
import { ReactRelayContext } from 'react-relay';

import VerifyPhone from './mutations/VerifyPhone';

const styles = theme => ({
  dialog: {
    width: theme.spacing.unit * 75,
    height: theme.spacing.unit * 34,
    [theme.breakpoints.down('721')]: {
      width: '100%',
    },
  },
  inputContainer: {
    marginLeft: '33%',
    marginRight: '33%',
    width: theme.spacing.unit * 27,
    marginTop: theme.spacing.unit * 2,
    [theme.breakpoints.down('721')]: {
      width: '100%',
      maxWidth: '100%',
      marginLeft: theme.spacing.unit * 1,
    },
  },
  inputLabel: {
    position: 'absolute',
    marginTop: theme.spacing.unit * 1.25,
    marginLeft: '28%',
    fontSize: 'medium',
    fontWeight: theme.spacing.unit * 62.5,
    color: '#483e3e',
    top: theme.spacing.unit * 3,
    [theme.breakpoints.down('721')]: {
      width: '90%',
      marginLeft: theme.spacing.unit * -1.5,
    },
  },
  info: {
    position: 'absolute',
    marginTop: theme.spacing.unit * 1.75,
    marginLeft: '33%',
    top: theme.spacing.unit * 6,
    fontSize: 'smaller',
    color: 'grey',
    [theme.breakpoints.down('721')]: {
      width: '90%',
      marginLeft: '5%',
    },
  },
});

let onOpen;
let helperFunction;
const defaultProps = {
  error: null,
  phone: '',
  countryCode: '+1',
  otp: '',
  toggleDialog: false,
  open: false,
};
class PhoneVerificationDialog extends React.Component {
  static contextType = ReactRelayContext;

  static open(func) {
    if (func) {
      helperFunction = func;
    }
    if (onOpen) {
      onOpen();
    }
  }

  phone = '';
  state = {
    ...defaultProps,
  };

  handleNumber = event => {
    if (!isNaN(event.target.value)) {
      this.setState({ phone: event.target.value });
    }
  };

  handleOTP = event => {
    if (!isNaN(event.target.value)) {
      this.setState({ otp: event.target.value });
    }
  };

  validation = () => {
    const data = this.state;
    if (!data.toggleDialog && data.phone) {
      return true;
    } else if (data.toggleDialog && data.otp) {
      return true;
    } else {
      return false;
    }
  };

  handleSubmit = event => {
    event.preventDefault();
    if (!this.validation()) {
      return;
    }
    const params = {
      id: this.props.id,
      phone: this.state.phone ? this.state.phone : this.phone,
      countryCode: this.state.countryCode,
      otp: this.state.otp,
    };
    const { environment } = this.context;
    this.setState({ error: null });
    if (this.props.id) {
      VerifyPhone.commit(environment, params)
        .then(res => {
          if (res.error) {
            this.setState({ error: res.error, phone: '', otp: '' });
          } else if (res.status) {
            this.phone = this.state.phone;
            this.setState({ toggleDialog: true, phone: '' });
          } else if (res.isOtp) {
            if (helperFunction) {
              helperFunction();
            }
            this.toggleDialog();
          }
        })
        .catch(error => {
          this.setState({ error: error.state.phone[0] });
        });
    }
  };

  hideVerificationDialog = () => {
    this.setState({ ...defaultProps }, () => {
      if (!helperFunction) {
        this.props.history.push('/');
      }
    });
  };

  toggleDialog = history => {
    this.setState({ ...defaultProps });
  };

  componentDidMount() {
    onOpen = message => {
      if (!this.state.open) {
        this.setState({ open: true });
      }
    };
  }

  render() {
    const hasError = !!this.state.error;
    const { classes } = this.props;
    return (
      <Dialog
        fullWidth
        aria-labelledby="post-request"
        open={this.state.open}
        onClose={this.hideVerificationDialog}
      >
        <DialogTitle id="post-request">
          {this.state.toggleDialog
            ? 'Verify your phone number'
            : 'Verify your phone number'}
        </DialogTitle>
        <DialogContent>
          <DialogContentText>
            {this.state.toggleDialog &&
              'Enter the confirmation code we just sent to ' + this.phone}
          </DialogContentText>
          <DialogContentText>
            {!this.state.toggleDialog &&
              !helperFunction &&
              'To ensure that Prepdup is a safe and caring environment for students to seek college and career advice,' +
                ' we require all our advisors to complete a phone verification.'}
          </DialogContentText>
          <DialogContentText>
            {!this.state.toggleDialog &&
              helperFunction &&
              'Verify your phone to get meeting reminders on your phone along with meeting join links.'}
          </DialogContentText>
          <form onSubmit={this.handleSubmit}>
            <FormControl
              fullWidth
              error={hasError}
              aria-describedby="text-error"
            >
              <Typography classes={{ root: classes.inputLabel }}>
                {this.state.toggleDialog ? '' : '+1'}
              </Typography>
              <TextField
                label={
                  this.state.toggleDialog
                    ? 'Type the confirmation code'
                    : 'Enter a mobile number'
                }
                value={
                  this.state.toggleDialog ? this.state.otp : this.state.phone
                }
                classes={{ root: classes.inputContainer }}
                fullWidth={false}
                required
                margin="dense"
                onChange={
                  this.state.toggleDialog ? this.handleOTP : this.handleNumber
                }
                error={hasError}
                helperText={hasError ? this.state.error : ' '}
              />
              {!this.state.toggleDialog && !hasError && (
                <Typography classes={{ root: classes.info }}>
                  A verification code will be sent to this number.
                </Typography>
              )}
            </FormControl>
          </form>
        </DialogContent>
        <DialogActions>
          <Button onClick={this.hideVerificationDialog}>CANCEL</Button>
          {!this.state.toggleDialog && (
            <Button
              color="primary"
              variant="raised"
              onClick={this.handleSubmit}
              disabled={this.state.phone ? false : true}
            >
              CONTINUE
            </Button>
          )}
          {this.state.toggleDialog && (
            <Button
              color="primary"
              variant="raised"
              onClick={this.handleSubmit}
              disabled={this.state.otp ? false : true}
            >
              CONFIRM
            </Button>
          )}
        </DialogActions>
      </Dialog>
    );
  }
}
export default withStyles(styles, { withTheme: true })(PhoneVerificationDialog);
