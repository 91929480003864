/* @flow */

import React from 'react';
import { graphql } from 'relay-runtime';
import { isPartner } from '../utils';

export default [
  {
    path: '',
    query: graphql`
      query landingHomeQuery {
        me {
          id
        }
      }
    `,
    components: () => [import(/* webpackChunkName: 'home' */ './Home')],
    render: ([Home], data, ctx) => {
      if (!data.me) {
        return typeof window !== 'undefined' && !isPartner()
          ? { redirect: `/early-access?return=${ctx.path}` }
          : {
              title: 'Prepdup • Enabling College and Career Success',
              component: <Home data={data} />,
              chunks: ['home'],
            };
      }
    },
  },
  {
    path: '/early-access',
    components: () => [import(/* webpackChunkName: 'home' */ './Home')],
    render: ([Home], data) => ({
      title: 'Prepdup • Enabling College and Career Success',
      component: <Home data={data} earlyAccess={true} />,
      chunks: ['home'],
    }),
  },
];
