/**
 * @flow
 * @relayHash 0ec36e3e2369b1db7813b47cbb27aa48
 */

/* eslint-disable */

'use strict';

/*::
import type { ConcreteRequest } from 'relay-runtime';
type Layout$ref = any;
type RequestDetails$ref = any;
export type requestsDetailsQueryVariables = {|
  id: string
|};
export type requestsDetailsQueryResponse = {|
  +request: ?{|
    +$fragmentRefs: RequestDetails$ref
  |},
  +$fragmentRefs: Layout$ref,
|};
export type requestsDetailsQuery = {|
  variables: requestsDetailsQueryVariables,
  response: requestsDetailsQueryResponse,
|};
*/


/*
query requestsDetailsQuery(
  $id: ID!
) {
  ...Layout
  request: node(id: $id) {
    __typename
    ...RequestDetails
    id
  }
}

fragment Layout on Query {
  me {
    ...Toolbar_me
    ...AutoUpdater_user
    id
    isAdvisor
  }
  ...Toolbar
  ...Monitor
}

fragment RequestDetails on Request {
  id
  shortText: text(truncate: 180)
  fullText: text
  author {
    displayName
    photoURL
    username
    id
  }
  createdAt(format: "MMM Do, YYYY")
  responses {
    id
    ...ResponseCard
  }
}

fragment ResponseCard on Response {
  id
  text(truncate: 180)
  fullText: text
  quote20m
  quote50m
  createdAt(format: "MMM Do, YYYY")
  author {
    username
    displayName
    photoURL
    id
  }
}

fragment Toolbar_me on User {
  id
  username
  displayName
  photoURL
  isAdvisor
}

fragment AutoUpdater_user on User {
  id
  timeZone
}

fragment Toolbar on Query {
  unreadMessageCount
}

fragment Monitor on Query {
  unreadMessageCount
}
*/

const node/*: ConcreteRequest*/ = (function(){
var v0 = [
  {
    "kind": "LocalArgument",
    "name": "id",
    "type": "ID!",
    "defaultValue": null
  }
],
v1 = [
  {
    "kind": "Variable",
    "name": "id",
    "variableName": "id",
    "type": "ID!"
  }
],
v2 = {
  "kind": "ScalarField",
  "alias": null,
  "name": "id",
  "args": null,
  "storageKey": null
},
v3 = {
  "kind": "ScalarField",
  "alias": null,
  "name": "username",
  "args": null,
  "storageKey": null
},
v4 = {
  "kind": "ScalarField",
  "alias": null,
  "name": "displayName",
  "args": null,
  "storageKey": null
},
v5 = {
  "kind": "ScalarField",
  "alias": null,
  "name": "photoURL",
  "args": null,
  "storageKey": null
},
v6 = [
  {
    "kind": "Literal",
    "name": "truncate",
    "value": 180,
    "type": "Int"
  }
],
v7 = {
  "kind": "ScalarField",
  "alias": "fullText",
  "name": "text",
  "args": null,
  "storageKey": null
},
v8 = {
  "kind": "ScalarField",
  "alias": null,
  "name": "createdAt",
  "args": [
    {
      "kind": "Literal",
      "name": "format",
      "value": "MMM Do, YYYY",
      "type": "String"
    }
  ],
  "storageKey": "createdAt(format:\"MMM Do, YYYY\")"
};
return {
  "kind": "Request",
  "fragment": {
    "kind": "Fragment",
    "name": "requestsDetailsQuery",
    "type": "Query",
    "metadata": null,
    "argumentDefinitions": (v0/*: any*/),
    "selections": [
      {
        "kind": "FragmentSpread",
        "name": "Layout",
        "args": null
      },
      {
        "kind": "LinkedField",
        "alias": "request",
        "name": "node",
        "storageKey": null,
        "args": (v1/*: any*/),
        "concreteType": null,
        "plural": false,
        "selections": [
          {
            "kind": "FragmentSpread",
            "name": "RequestDetails",
            "args": null
          }
        ]
      }
    ]
  },
  "operation": {
    "kind": "Operation",
    "name": "requestsDetailsQuery",
    "argumentDefinitions": (v0/*: any*/),
    "selections": [
      {
        "kind": "LinkedField",
        "alias": null,
        "name": "me",
        "storageKey": null,
        "args": null,
        "concreteType": "User",
        "plural": false,
        "selections": [
          (v2/*: any*/),
          (v3/*: any*/),
          (v4/*: any*/),
          (v5/*: any*/),
          {
            "kind": "ScalarField",
            "alias": null,
            "name": "isAdvisor",
            "args": null,
            "storageKey": null
          },
          {
            "kind": "ScalarField",
            "alias": null,
            "name": "timeZone",
            "args": null,
            "storageKey": null
          }
        ]
      },
      {
        "kind": "ScalarField",
        "alias": null,
        "name": "unreadMessageCount",
        "args": null,
        "storageKey": null
      },
      {
        "kind": "LinkedField",
        "alias": "request",
        "name": "node",
        "storageKey": null,
        "args": (v1/*: any*/),
        "concreteType": null,
        "plural": false,
        "selections": [
          {
            "kind": "ScalarField",
            "alias": null,
            "name": "__typename",
            "args": null,
            "storageKey": null
          },
          (v2/*: any*/),
          {
            "kind": "InlineFragment",
            "type": "Request",
            "selections": [
              {
                "kind": "ScalarField",
                "alias": "shortText",
                "name": "text",
                "args": (v6/*: any*/),
                "storageKey": "text(truncate:180)"
              },
              (v7/*: any*/),
              {
                "kind": "LinkedField",
                "alias": null,
                "name": "author",
                "storageKey": null,
                "args": null,
                "concreteType": "User",
                "plural": false,
                "selections": [
                  (v4/*: any*/),
                  (v5/*: any*/),
                  (v3/*: any*/),
                  (v2/*: any*/)
                ]
              },
              (v8/*: any*/),
              {
                "kind": "LinkedField",
                "alias": null,
                "name": "responses",
                "storageKey": null,
                "args": null,
                "concreteType": "Response",
                "plural": true,
                "selections": [
                  (v2/*: any*/),
                  {
                    "kind": "ScalarField",
                    "alias": null,
                    "name": "text",
                    "args": (v6/*: any*/),
                    "storageKey": "text(truncate:180)"
                  },
                  (v7/*: any*/),
                  {
                    "kind": "ScalarField",
                    "alias": null,
                    "name": "quote20m",
                    "args": null,
                    "storageKey": null
                  },
                  {
                    "kind": "ScalarField",
                    "alias": null,
                    "name": "quote50m",
                    "args": null,
                    "storageKey": null
                  },
                  (v8/*: any*/),
                  {
                    "kind": "LinkedField",
                    "alias": null,
                    "name": "author",
                    "storageKey": null,
                    "args": null,
                    "concreteType": "User",
                    "plural": false,
                    "selections": [
                      (v3/*: any*/),
                      (v4/*: any*/),
                      (v5/*: any*/),
                      (v2/*: any*/)
                    ]
                  }
                ]
              }
            ]
          }
        ]
      }
    ]
  },
  "params": {
    "operationKind": "query",
    "name": "requestsDetailsQuery",
    "id": null,
    "text": "query requestsDetailsQuery(\n  $id: ID!\n) {\n  ...Layout\n  request: node(id: $id) {\n    __typename\n    ...RequestDetails\n    id\n  }\n}\n\nfragment Layout on Query {\n  me {\n    ...Toolbar_me\n    ...AutoUpdater_user\n    id\n    isAdvisor\n  }\n  ...Toolbar\n  ...Monitor\n}\n\nfragment RequestDetails on Request {\n  id\n  shortText: text(truncate: 180)\n  fullText: text\n  author {\n    displayName\n    photoURL\n    username\n    id\n  }\n  createdAt(format: \"MMM Do, YYYY\")\n  responses {\n    id\n    ...ResponseCard\n  }\n}\n\nfragment ResponseCard on Response {\n  id\n  text(truncate: 180)\n  fullText: text\n  quote20m\n  quote50m\n  createdAt(format: \"MMM Do, YYYY\")\n  author {\n    username\n    displayName\n    photoURL\n    id\n  }\n}\n\nfragment Toolbar_me on User {\n  id\n  username\n  displayName\n  photoURL\n  isAdvisor\n}\n\nfragment AutoUpdater_user on User {\n  id\n  timeZone\n}\n\nfragment Toolbar on Query {\n  unreadMessageCount\n}\n\nfragment Monitor on Query {\n  unreadMessageCount\n}\n",
    "metadata": {}
  }
};
})();
// prettier-ignore
(node/*: any*/).hash = 'f739933f849a78d16ef673bb9c727281';
module.exports = node;
