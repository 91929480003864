import ForumIcon from '@material-ui/icons/Forum';
import AssignmentIcon from '@material-ui/icons/Assignment';
import DateRangeIcon from '@material-ui/icons/DateRange';
import VerifiedUserIcon from '@material-ui/icons/VerifiedUser';
import ExitToAppIcon from '@material-ui/icons/ExitToApp';

import { ViewMode } from './constants';

const messages = {
  key: 'msg',
  href: '/messages',
  text: 'Messages',
  icon: ForumIcon,
};

const advmessages = {
  key: 'msg',
  href: '/advising/messages',
  text: 'Messages',
  icon: ForumIcon,
};

const meetings = {
  key: 'app',
  href: '/meetings',
  text: 'Meetings',
  icon: DateRangeIcon,
};

const advmeetings = {
  key: 'app',
  href: '/advising/meetings',
  text: 'Meetings',
  icon: DateRangeIcon,
};

const myrequests = {
  key: 'mreq',
  href: '/requests',
  text: 'My Requests',
  icon: AssignmentIcon,
};

const advrequests = {
  key: 'areq',
  href: '/advising/requests',
  text: 'Requests',
  icon: AssignmentIcon,
};

const becomeAnAdvisor = {
  key: 'ba',
  href: '/apply',
  text: 'Become an Advisor',
  icon: VerifiedUserIcon,
};

const signIn = {
  key: 'lg',
  href: '/login',
  text: 'Sign In',
  icon: ExitToAppIcon,
};

const guestMenu = [signIn];
const userMenu = [meetings, myrequests, messages];
const advisorMenu = [advmessages, advrequests, advmeetings];

/**
 * Returns the list of manu items based on user's status and view mode.
 */
export default function menu(user, viewMode) {
  if (!user) {
    return guestMenu;
  }

  if (user.isAdvisor === undefined) {
    throw new Error();
  }

  if (viewMode === ViewMode.SEEKER) {
    return user.isAdvisor ? [...userMenu] : [becomeAnAdvisor, ...userMenu];
  }

  if (viewMode === ViewMode.ADVISOR) {
    return advisorMenu;
  }

  throw new Error(`Unsupported user view mode: ${viewMode}`);
}
