/**
 * @flow
 * @relayHash baf55dd2b5e9138a4dfb35d31c6269ac
 */

/* eslint-disable */

'use strict';

/*::
import type { ConcreteRequest } from 'relay-runtime';
type AdvisorRequests$ref = any;
type AdvisorRequestsNavigation$ref = any;
type Layout$ref = any;
export type advisorRequestsQueryVariables = {||};
export type advisorRequestsQueryResponse = {|
  +$fragmentRefs: Layout$ref & AdvisorRequestsNavigation$ref & AdvisorRequests$ref
|};
export type advisorRequestsQuery = {|
  variables: advisorRequestsQueryVariables,
  response: advisorRequestsQueryResponse,
|};
*/


/*
query advisorRequestsQuery {
  ...Layout
  ...AdvisorRequestsNavigation
  ...AdvisorRequests
}

fragment Layout on Query {
  me {
    ...Toolbar_me
    ...AutoUpdater_user
    id
    isAdvisor
  }
  ...Toolbar
  ...Monitor
}

fragment AdvisorRequestsNavigation on Query {
  responses(filter: "is:mine") {
    totalCount
  }
}

fragment AdvisorRequests on Query {
  me {
    id
  }
  requests {
    edges {
      node {
        id
        ...AdvisorRequestCard
      }
    }
  }
}

fragment AdvisorRequestCard on Request {
  id
  text(truncate: 180)
  fullText: text
  createdAt(format: "MMM Do, YYYY")
  author {
    id
    displayName
    photoURL
  }
  responses {
    author {
      id
    }
    id
  }
}

fragment Toolbar_me on User {
  id
  username
  displayName
  photoURL
  isAdvisor
}

fragment AutoUpdater_user on User {
  id
  timeZone
}

fragment Toolbar on Query {
  unreadMessageCount
}

fragment Monitor on Query {
  unreadMessageCount
}
*/

const node/*: ConcreteRequest*/ = (function(){
var v0 = {
  "kind": "ScalarField",
  "alias": null,
  "name": "id",
  "args": null,
  "storageKey": null
},
v1 = {
  "kind": "ScalarField",
  "alias": null,
  "name": "displayName",
  "args": null,
  "storageKey": null
},
v2 = {
  "kind": "ScalarField",
  "alias": null,
  "name": "photoURL",
  "args": null,
  "storageKey": null
};
return {
  "kind": "Request",
  "fragment": {
    "kind": "Fragment",
    "name": "advisorRequestsQuery",
    "type": "Query",
    "metadata": null,
    "argumentDefinitions": [],
    "selections": [
      {
        "kind": "FragmentSpread",
        "name": "Layout",
        "args": null
      },
      {
        "kind": "FragmentSpread",
        "name": "AdvisorRequestsNavigation",
        "args": null
      },
      {
        "kind": "FragmentSpread",
        "name": "AdvisorRequests",
        "args": null
      }
    ]
  },
  "operation": {
    "kind": "Operation",
    "name": "advisorRequestsQuery",
    "argumentDefinitions": [],
    "selections": [
      {
        "kind": "LinkedField",
        "alias": null,
        "name": "me",
        "storageKey": null,
        "args": null,
        "concreteType": "User",
        "plural": false,
        "selections": [
          (v0/*: any*/),
          {
            "kind": "ScalarField",
            "alias": null,
            "name": "username",
            "args": null,
            "storageKey": null
          },
          (v1/*: any*/),
          (v2/*: any*/),
          {
            "kind": "ScalarField",
            "alias": null,
            "name": "isAdvisor",
            "args": null,
            "storageKey": null
          },
          {
            "kind": "ScalarField",
            "alias": null,
            "name": "timeZone",
            "args": null,
            "storageKey": null
          }
        ]
      },
      {
        "kind": "ScalarField",
        "alias": null,
        "name": "unreadMessageCount",
        "args": null,
        "storageKey": null
      },
      {
        "kind": "LinkedField",
        "alias": null,
        "name": "responses",
        "storageKey": "responses(filter:\"is:mine\")",
        "args": [
          {
            "kind": "Literal",
            "name": "filter",
            "value": "is:mine",
            "type": "String"
          }
        ],
        "concreteType": "ResponseConnection",
        "plural": false,
        "selections": [
          {
            "kind": "ScalarField",
            "alias": null,
            "name": "totalCount",
            "args": null,
            "storageKey": null
          }
        ]
      },
      {
        "kind": "LinkedField",
        "alias": null,
        "name": "requests",
        "storageKey": null,
        "args": null,
        "concreteType": "RequestConnection",
        "plural": false,
        "selections": [
          {
            "kind": "LinkedField",
            "alias": null,
            "name": "edges",
            "storageKey": null,
            "args": null,
            "concreteType": "RequestEdge",
            "plural": true,
            "selections": [
              {
                "kind": "LinkedField",
                "alias": null,
                "name": "node",
                "storageKey": null,
                "args": null,
                "concreteType": "Request",
                "plural": false,
                "selections": [
                  (v0/*: any*/),
                  {
                    "kind": "ScalarField",
                    "alias": null,
                    "name": "text",
                    "args": [
                      {
                        "kind": "Literal",
                        "name": "truncate",
                        "value": 180,
                        "type": "Int"
                      }
                    ],
                    "storageKey": "text(truncate:180)"
                  },
                  {
                    "kind": "ScalarField",
                    "alias": "fullText",
                    "name": "text",
                    "args": null,
                    "storageKey": null
                  },
                  {
                    "kind": "ScalarField",
                    "alias": null,
                    "name": "createdAt",
                    "args": [
                      {
                        "kind": "Literal",
                        "name": "format",
                        "value": "MMM Do, YYYY",
                        "type": "String"
                      }
                    ],
                    "storageKey": "createdAt(format:\"MMM Do, YYYY\")"
                  },
                  {
                    "kind": "LinkedField",
                    "alias": null,
                    "name": "author",
                    "storageKey": null,
                    "args": null,
                    "concreteType": "User",
                    "plural": false,
                    "selections": [
                      (v0/*: any*/),
                      (v1/*: any*/),
                      (v2/*: any*/)
                    ]
                  },
                  {
                    "kind": "LinkedField",
                    "alias": null,
                    "name": "responses",
                    "storageKey": null,
                    "args": null,
                    "concreteType": "Response",
                    "plural": true,
                    "selections": [
                      {
                        "kind": "LinkedField",
                        "alias": null,
                        "name": "author",
                        "storageKey": null,
                        "args": null,
                        "concreteType": "User",
                        "plural": false,
                        "selections": [
                          (v0/*: any*/)
                        ]
                      },
                      (v0/*: any*/)
                    ]
                  }
                ]
              }
            ]
          }
        ]
      }
    ]
  },
  "params": {
    "operationKind": "query",
    "name": "advisorRequestsQuery",
    "id": null,
    "text": "query advisorRequestsQuery {\n  ...Layout\n  ...AdvisorRequestsNavigation\n  ...AdvisorRequests\n}\n\nfragment Layout on Query {\n  me {\n    ...Toolbar_me\n    ...AutoUpdater_user\n    id\n    isAdvisor\n  }\n  ...Toolbar\n  ...Monitor\n}\n\nfragment AdvisorRequestsNavigation on Query {\n  responses(filter: \"is:mine\") {\n    totalCount\n  }\n}\n\nfragment AdvisorRequests on Query {\n  me {\n    id\n  }\n  requests {\n    edges {\n      node {\n        id\n        ...AdvisorRequestCard\n      }\n    }\n  }\n}\n\nfragment AdvisorRequestCard on Request {\n  id\n  text(truncate: 180)\n  fullText: text\n  createdAt(format: \"MMM Do, YYYY\")\n  author {\n    id\n    displayName\n    photoURL\n  }\n  responses {\n    author {\n      id\n    }\n    id\n  }\n}\n\nfragment Toolbar_me on User {\n  id\n  username\n  displayName\n  photoURL\n  isAdvisor\n}\n\nfragment AutoUpdater_user on User {\n  id\n  timeZone\n}\n\nfragment Toolbar on Query {\n  unreadMessageCount\n}\n\nfragment Monitor on Query {\n  unreadMessageCount\n}\n",
    "metadata": {}
  }
};
})();
// prettier-ignore
(node/*: any*/).hash = '67c28ae4b4ccb77693e0e6530a198421';
module.exports = node;
