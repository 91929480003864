/**
 * @flow
 * @relayHash 62e0516a86e7fdd1c1a2076a493a26f7
 */

/* eslint-disable */

'use strict';

/*::
import type { ConcreteRequest } from 'relay-runtime';
type Monitor$ref = any;
export type MonitorQueryVariables = {||};
export type MonitorQueryResponse = {|
  +$fragmentRefs: Monitor$ref
|};
export type MonitorQuery = {|
  variables: MonitorQueryVariables,
  response: MonitorQueryResponse,
|};
*/


/*
query MonitorQuery {
  ...Monitor
}

fragment Monitor on Query {
  unreadMessageCount
}
*/

const node/*: ConcreteRequest*/ = {
  "kind": "Request",
  "fragment": {
    "kind": "Fragment",
    "name": "MonitorQuery",
    "type": "Query",
    "metadata": null,
    "argumentDefinitions": [],
    "selections": [
      {
        "kind": "FragmentSpread",
        "name": "Monitor",
        "args": null
      }
    ]
  },
  "operation": {
    "kind": "Operation",
    "name": "MonitorQuery",
    "argumentDefinitions": [],
    "selections": [
      {
        "kind": "ScalarField",
        "alias": null,
        "name": "unreadMessageCount",
        "args": null,
        "storageKey": null
      }
    ]
  },
  "params": {
    "operationKind": "query",
    "name": "MonitorQuery",
    "id": null,
    "text": "query MonitorQuery {\n  ...Monitor\n}\n\nfragment Monitor on Query {\n  unreadMessageCount\n}\n",
    "metadata": {}
  }
};
// prettier-ignore
(node/*: any*/).hash = '8bf13e5274a4d470ab1a7409dcd744dd';
module.exports = node;
